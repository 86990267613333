import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import {Layout} from "./components";
import {Post, Home} from "./pages";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const languages = ['en', 'th'];

const App = () => {

    const {i18n} = useTranslation();

    const path = useLocation().pathname;

    useEffect(() => {
        if (typeof window !== undefined) {
            const language = localStorage.getItem("localization");
            if (language !== 'th' && language !== null && language !== undefined) {
                i18n.changeLanguage(language);
            }
        }
    }, [i18n]);


    useEffect(() => {
        const languageFromPath = path.split("/").find(lang => languages.includes(lang));
        i18n.changeLanguage(languageFromPath ?? "th");
    }, [path])

    const pathArray = [`/${i18n.language}`, "/", "en", "th"];

    return (
        <div>
            <Routes>
                {pathArray.map((path) =>
                    <Route path={path} element={<Layout/>} key={path}>
                        <Route path={`:year`} element={<Home/>}/>
                        <Route index element={<Home/>}/>
                        <Route path={"post/:id"} element={<Post/>}/>
                        <Route path={"*"} element={<Navigate replace to={"/"}/>}/>
                    </Route>
                )}
            </Routes>
        </div>
    );
}

export default App;
