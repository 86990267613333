import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import styles from "./Post.module.scss";
import {InfiniteSlider} from "../components/ui-kit";
import client from "../api/client";
import {useTranslation} from "react-i18next";
import {getCurrentDate} from "../helpers/getCurrentDate";

const Post = () => {

    const {id} = useParams();

    const [post, setPost] = useState({});
    const [images, setImages] = useState([]);

    const {i18n} = useTranslation();

    useEffect(() => {

        const getPost = async () => {
            try {
                const {data} = await client.get({
                    url: `posts/${id}`
                });
                setPost(data.post)
            } catch (e) {
                throw new Error(e?.response?.data?.message);
            }
        }
        getPost()
    }, [id]);

    useEffect(() => {
        if (!!post?.id) {
            const postImages = post.media?.map(({original_url}) => original_url);
            setImages(postImages.splice(1));
        }
    }, [post]);

    const translationIndex = i18n.language === "en" ? 0 : 1;

    const getPostInfoByTranslation = () => {
        if (!!post.translations[translationIndex].title ) {
            return {
                title: post.translations[translationIndex].title,
                text_1: post.translations[translationIndex].text_1,
                text_2: post.translations[translationIndex].text_2,
            }
        } else {
            return {
                title: post.translations[1 - translationIndex].title,
                text_1: post.translations[1 - translationIndex].text_1,
                text_2: post.translations[1 - translationIndex].text_2,
            }
        }
    }

    return (
        <main className={styles.post}>
            {!!post?.id &&
                <>
                    <section className={styles.general}>
                        <p className={styles.date}>{getCurrentDate(post.created_at, i18n.language)}</p>
                        <h2 className={styles.title}>
                            {getPostInfoByTranslation().title}
                        </h2>
                        {!!post?.media.length &&
                            <div className={styles.picture}>
                                <img
                                    src={post.media[0].original_url}
                                    loading={"lazy"}
                                    width={300}
                                    height={300}
                                    alt={getPostInfoByTranslation().title}
                                />
                            </div>
                        }
                    </section>
                    {!!getPostInfoByTranslation().text_1 &&
                        <article
                            className={styles.article}
                            dangerouslySetInnerHTML={
                                {
                                    __html: getPostInfoByTranslation().text_1
                                }
                            }
                        />
                    }
                    {!!post?.media.length > 1 &&
                        <section className={styles.slider}>
                            <InfiniteSlider children={images}/>
                        </section>
                    }
                    {!!getPostInfoByTranslation().text_2 &&
                        <article
                            className={styles.article}
                            dangerouslySetInnerHTML={
                                {
                                    __html: getPostInfoByTranslation().text_2
                                }
                            }
                        />
                    }
                </>
            }
        </main>
    )
}

export default Post;