import styles from "./Card.module.scss";

const Card = ({date, title, description, img}) => {

    return (
        <div className={styles.card}>
            <div className={styles.year}>{date}</div>
            <div className={styles.content}>
                <div className={styles.picture}>
                    {img &&
                        <img src={img} alt="Card avatar" width={220} height={160} loading={"lazy"} />
                    }
                </div>
                <div className={styles.info}>
                    <p className={styles.year}>{date}</p>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.description}>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default Card;